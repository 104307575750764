import { LazyLotteryTeaser, LazyScratchcardTeaser } from '#components'
import type {
  LotteryId,
  LotteryTeaserPayload,
  Maybe,
  QuickPickConfiguration,
  ScratchcardTeaserPayload,
  ScratchcardVisualParameters,
} from '~/@types/generated/backend/graphql-schema-types'
import type { LotteryTeaserProps } from '~/components/LotteryTeaser/types'
import type { ScratchcardTeaserProps } from '~/features/Scratchcard/ScratchcardTeaser.vue'

export type CustomLotteryTeaserPayload = Omit<
  LotteryTeaserPayload,
  'name' | 'quickPick'
> & {
  quickPick?: Maybe<Pick<QuickPickConfiguration, 'plays'>>
}

export type CustomScratchcardTeaserPayload = Omit<
  ScratchcardTeaserPayload,
  'amount' | 'price' | 'visualParameters'
> & {
  visualParameters?: Maybe<
    Omit<ScratchcardVisualParameters, 'scratchArea' | 'teaserImageProperties'>
  >
}

type Teaser =
  | {
      component: typeof LazyLotteryTeaser
      id: LotteryId
      props: LotteryTeaserProps
    }
  | {
      component: typeof LazyScratchcardTeaser
      id: string
      props: ScratchcardTeaserProps
    }

export const mapToTeaserComponents = (
  teasers: (CustomLotteryTeaserPayload | CustomScratchcardTeaserPayload)[],
  licensedTerritory: string,
  refetch: () => void,
  variant: LotteryTeaserProps['variant'] = 'full',
) =>
  teasers.map<Teaser>((teaser) => {
    const isLottery = teaser.__typename === 'LotteryTeaserPayload'
    return {
      component: isLottery ? LazyLotteryTeaser : LazyScratchcardTeaser,
      id: isLottery
        ? teaser.id
        : (teaser as CustomScratchcardTeaserPayload).name,
      props: {
        ...teaser,
        licensedTerritory,
        ...(isLottery && { onUpdateTeasers: refetch, variant }),
      },
    } as Teaser
  })
